import React from "react";
import { Link } from "react-router-dom";

export default () => {
  return <>
  <h1>Cool Stuff</h1>
  <ul>
    <li><Link to="/stuffs/debounce-vs-throttle">Debounce vs. Throttle</Link></li>
  </ul>
  </>
};
