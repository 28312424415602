import React from "react";
import { Link } from "react-router-dom";

export default () => {
  return <ul>
    <li><Link to="/blog/1">The Most Basic HTML Template</Link></li>
    <li><Link to="/blog/2">How to Install Homebrew</Link></li>
    <li><Link to="/blog/3">How to Install Node.js</Link></li>
    <li><Link to="/blog/4">How to Create a Express API in Node.js</Link></li>
    <li><Link to="/blog/5">Override React Component Render in Jest Test</Link></li>
  </ul>
};
