import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";

import App from "./components/App/App";
import Blog from "./components/Blog/Blog";
import DebounceAndThrottle from "./components/Examples/DebounceAndThrottle/DebounceAndThrottle";
import Home from "./components/Home/Home";
import Post from "./components/Post/Post";
import Stuffs from "./components/Stuffs/Stuffs";

import "./index.scss";

ReactDOM.render(
  <Router>
    <App>
      <Route exact path="/" component={Home} />
      <Route exact path="/blog" component={Blog} />
      <Route exact path="/blog/:id" component={Post} />
      <Route exact path="/stuffs" component={Stuffs} />
      <Route exact path="/stuffs/debounce-vs-throttle" component={DebounceAndThrottle} />
    </App>
  </Router>,
  document.getElementById("root")
);
