import React, {useEffect, useState} from "react";
import ReactMarkdown from 'react-markdown'
import {materialDark} from 'react-syntax-highlighter/dist/esm/styles/prism'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import { useParams } from "react-router-dom";

const renderers = {
  code: ({language, value}) => {
    return <SyntaxHighlighter style={materialDark} language={language} children={value} />
  }
}

export default () => {
  const [post, setPost] = useState("")
  const { id } = useParams();

  useEffect(() => {
    const path = require(`./${id}.md`);
    fetch(path).then(res => res.text()).then(postContent => setPost(postContent))
  }, [])

  return <ReactMarkdown renderers={renderers} children={post} />
};
