import React from "react";
import Header from "../Header/Header";

import "./Home.scss";

const firstEmployedYear = 2012;
const currentYear = new Date().getFullYear();
const totalYearsEmployed = currentYear - firstEmployedYear;

export default () => (
  <div className="home">
    <Header />
    <h3>
      full-stack web developer with over {totalYearsEmployed} years of
      experience
    </h3>
    <div className="home__row">
      <a
        href="https://docs.google.com/viewer?url=https://docs.google.com/document/d/1zjQd3ozFA33lc0ZBFR2aEoHT70Xet7cD9WdnH3QjAQ8/export?format=pdf"
        rel="noopener noreferrer"
        target="_blank"
      >
        check out my resume
      </a>
    </div>
    <div className="home__row">
      <span>find me on </span>
      <a
        href="https://github.com/lindenmelvin"
        rel="noopener noreferrer"
        target="_blank"
      >
        github
      </a>
      <span> or </span>
      <a
        href="https://www.linkedin.com/in/lindenmelvin/"
        rel="noopener noreferrer"
        target="_blank"
      >
        linkedin
      </a>
    </div>
    <div className="home__row">
      <a href="mailto:lindenallynmelvin@gmail.com">send me an email</a>
      <span> or </span>
      <a href="tel:1-619-929-0889">give me a call</a>
    </div>
    <div className="home__row">have a wonderful day <span aria-label="smile" role="img">🙂</span></div>
  </div>
);
