import React, {useRef, useState} from "react";
import debounce from "lodash.debounce";
import throttle from "lodash.throttle";

import "./DebounceAndThrottle.style.scss";

export default () => {
  const [baseDots, setBaseDots] = useState("")
  const [throttleDots, setThrottleDots] = useState("")
  const [debounceDots, setDebounceDots] = useState("")

  const onBaseMousMove = () => setBaseDots(baseDots + ".");
  const onThrottleMouseMove = useRef(throttle((dots) => {
    setThrottleDots(dots + ".")
  }, 500)).current
  const onDebounceMouseMove = useRef(debounce((dots) => {
    setDebounceDots(dots + ".")
  }, 500)).current

  return <div>
    <h1>Debounce vs. Throttle</h1>
    <p>
      Although these concepts are relatively simple, it can be tricky to remember which is right for a given scenario.
    </p>

    <h2>Base Case</h2>
    <p>Before we look at how throttling and debouncing work, let's first look at events are fired normally:</p>
    <div>
      <div 
        className="debounce-and-throttle__zone" 
        onMouseMove={onBaseMousMove}
      >
        Move Your Mouse Over Me!
      </div>
      <div className="debounce-and-throttle__events">
        <div>Mouse Move Events:</div>
        <div>{baseDots}</div>
      </div>
    </div>
    <p>Each dot represents a mouse move event being fired. That's a lot of events!</p>

    <h2>Throttle</h2>
    <p>Now, let's take a look at the same example, but this time, we will have a 500ms <strong>throttle</strong> to the mouse over events.</p>
    <div>
      <div 
        className="debounce-and-throttle__zone" 
        onMouseMove={() => onThrottleMouseMove(throttleDots)}
      >
        Move Your Mouse Over Me!
      </div>
      <div className="debounce-and-throttle__events">
        <div>Mouse Move Events:</div>
        <div>{throttleDots}</div>
      </div>
    </div>
    <p>Notice how the number of events is fewer than before. Throttling slows down the rate of events being fired.</p>

    <h2>Debounce</h2>
    <p>Now, we will have a 500ms <strong>debounce</strong> to the mouse over events.</p>
    <div>
      <div 
        className="debounce-and-throttle__zone" 
        onMouseMove={() => onDebounceMouseMove(debounceDots)}
      >
        Move Your Mouse Over Me!
      </div>
      <div className="debounce-and-throttle__events">
        <div>Mouse Move Events:</div>
        <div>{debounceDots}</div>
      </div>
      <p>Notice how the events happen 500ms <em>after</em> the mouse move stops. Debouncing waits until after the last event has fired to fire the event.</p>
    </div>
  </div>
};